dmx.Component('s3-upload', {

    initialData: {
        data: null,
        file: null,
        state: {
            idle: true,
            ready: false,
            uploading: false,
            done: false
        },
        uploadProgress: {
            position: 0,
            total: 0,
            percent: 0
        },
        lastError: {
            status: 0,
            message: '',
            response: null
        }
    },

    attributes: {
        url: {
            type: String,
            default: null
        },

        prop: {
            type: String,
            default: 'url'
        },

        accept: {
            type: String,
            default: null
        },

        autoupload: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        abort: function() {
            this.abort();
        },

        reset: function() {
            this.reset();
        },

        select: function() {
            this.input.click();
        },

        upload: function() {
            this.upload();
        }
    },

    events: {
        start: Event, // when starting an ajax call
        done: Event, // when ajax call completed (success and error)
        error: Event, // server error or javascript error (json parse or network transport) or timeout error
        abort: Event, // ajax call was aborted
        success: Event, // successful ajax call,
        upload: ProgressEvent // on upload progress
    },

    render: function(node) {
        this.$node.addEventListener('dragover', this.onDragover.bind(this));
        this.$node.addEventListener('drop', this.onDrop.bind(this));
        this.$node.addEventListener('click', this.onClick.bind(this));
        
        this.input = document.createElement('input');
        this.input.type = 'file';
        this.input.accept = this.props.accept || '*/*';
        this.input.addEventListener('change', this.onChange.bind(this));

        this.xhr = new XMLHttpRequest();
        this.xhr.addEventListener('abort', this.onAbort.bind(this));
        this.xhr.addEventListener('error', this.onError.bind(this));
        this.xhr.addEventListener('timeout', this.onTimeout.bind(this));
        this.xhr.addEventListener('load', this.onLoad.bind(this));
        this.xhr.upload.addEventListener('progress', this.onProgress.bind(this));

        this.$parse();
    },

    update: function(props) {
        if (this.props.accept != props.accept) {
            this.input.accept = this.props.accept || '*/*';
        }
    },

    onDragover: function(e) {
        e.stopPropagation();
        e.preventDefault();

        e.dataTransfer.dropEffect = e.dataTransfer.items.length == 1 && this.validateDrag(e.dataTransfer.items[0]) ? 'copy' : 'none';
    },

    onDrop: function(e) {
        e.stopPropagation();
        e.preventDefault();

        if (e.dataTransfer.files.length == 1) {
            this.updateFile(e.dataTransfer.files[0]);
        }
    },

    onClick: function(e) {
        this.input.click();
    },

    onChange: function(e) {
        this.updateFile(e.target.files[0]);
        this.input.value = '';
        this.input.type = '';
        this.input.type = 'file';
    },

    onAbort: function(e) {
        this.set({
            data: null,
            state: {
                idle: false,
                ready: true,
                uploading: false,
                done: false
            },
            uploadProgress: {
                position: 0,
                total: 0,
                percent: 0
            }
        });

        this.dispatchEvent('abort');
        this.dispatchEvent('done');
    },

    onError: function(e) {
        if (e instanceof ProgressEvent) {
            e = 'Network error, perhaps no CORS set';
        }

        this.set({
            data: null,
            state: {
                idle: false,
                ready: true,
                uploading: false,
                done: false
            },
            uploadProgress: {
                position: 0,
                total: 0,
                percent: 0
            },
            lastError: {
                status: 0,
                message: e,
                response: null
            }
        });

        console.error(e);

        this.dispatchEvent('error');
        this.dispatchEvent('done');
    },

    onTimeout: function(e) {
        this.onError('Execution timeout');
    },

    onLoad: function(e) {
        if (this.xhr.status >= 400) {
            this.onError(this.xhr.responseText);
        } else {
            this.set({
                state: {
                    idle: false,
                    ready: false,
                    uploading: false,
                    done: true
                },
                uploadProgress: {
                    position: this.file.size,
                    total: this.file.size,
                    percent: 100
                }
            });

            this.dispatchEvent('success');
            this.dispatchEvent('done');
        }
    },

    onProgress: function(e) {
        this.set({
            state: {
                idle: false,
                ready: false,
                uploading: true,
                done: false
            },
            uploadProgress: {
                position: e.loaded,
                total: this.file.size,
                percent: Math.ceil(e.loaded / e.total * 100)
            }
        });

        this.dispatchEvent('upload', {
            lengthComputable: e.lengthComputable,
            loaded: e.loaded,
            total: e.total
        });
    },

    validateDrag: function(item) {
        // todo validate accept type
        return item.kind == 'file';
    },

    updateFile: function(file) {
        var info = {
            name: file.name,
            size: file.size,
            type: file.type,
            date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
            dataUrl: null
        };

        if (file.type.indexOf('image/') !== -1 && !file.reader) {
            file.reader = new FileReader();

            file.reader.onload = function(e) {
                info.dataUrl = e.target.result;
                dmx.requestUpdate();
            }.bind(this);

            file.reader.readAsDataURL(file);
        }

        this.file = file;

        this.set({
            file: info,
            state: {
                idle: false,
                ready: true,
                uploading: false,
                done: false
            }
        });

        if (this.props.autoupload) {
            this.upload();
        }
    },

    abort: function() {
        this.xhr.abort();
    },

    reset: function() {
        this.abort();
        this.file = null;
        this.set({
            data: null,
            file: null,
            state: {
                idle: true,
                ready: false,
                uploading: false,
                done: false
            },
            uploadProgress: {
                position: 0,
                total: 0,
                percent: 0
            },
            lastError: {
                status: 0,
                message: '',
                response: null
            }
        });
    },

    upload: function() {
        if (!this.props.url) {
            this.onError('No url attribute is set');
            return;
        }

        this.set({
            state: {
                idle: false,
                ready: false,
                uploading: true,
                done: false
            }
        });

        this.dispatchEvent('start');

        var xhr = new XMLHttpRequest();
        xhr.onabort = this.onAbort.bind(this);
        xhr.onerror = this.onError.bind(this);
        xhr.onload = this.upload2.bind(this, xhr);
        xhr.open('GET', this.props.url + '?name=' + encodeURIComponent(this.file.name));
        xhr.send();
    },

    upload2: function(xhr) {
        try {
            var data = JSON.parse(xhr.responseText);
            var url = data[this.props.prop]
            this.set('data', data);
            this.xhr.open('PUT', url);
            this.xhr.setRequestHeader('Content-Type', this.file.type);
            if (url.indexOf('x-amz-acl=') != -1) {
                // could be improved
                var acl = url.substr(url.indexOf('x-amz-acl=') + 10);
                if (acl.indexOf('&') != -1) acl = acl.substr(0, acl.indexOf('&'));
                this.xhr.setRequestHeader('x-amz-acl', acl);
            }
            this.xhr.send(this.file);
        } catch (err) {
            this.onError(err);
        }
    }

});